import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/SEO"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

class Contact extends React.Component {
  render() {
    const pageData = this.props.data.contentfulPage
    const heading = pageData.heading
    const content = pageData.bodyContent
    const image = pageData.featuredImage

    return (
      <Layout>
        <SEO title="Contact" description="If you like what you see, get in touch and I could create something perfect for you!"/>

        <Container>
          <div className="heading">
            {heading
              ? documentToReactComponents(heading.json)
              : `Get in touch! Send me a little message.`}
          </div>
          {image ? (
            <Img
              className="hero-image"
              alt={image.title}
              fluid={{ ...image.fluid }}
            />
          ) : (
            <div className="hero-image empty"></div>
          )}

          <div className="content">
            {content ? documentToReactComponents(content.json) : ``}

            <form
              id="contact-form"
              name="Contact Page"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/success"
            >
              

              <div className="form-group w-50">
                <label htmlFor="name">
                  Name <span className="asterisk">*</span>
                  <input type="text" name="name" id="name" />
                </label>
              </div>
              <div className="form-group w-50">
                <label htmlFor="email">
                  Email <span className="asterisk">*</span>
                  <input type="email" name="email" id="email" />
                </label>
              </div>
              <div className="form-group nomargin">
                <input className="hidden" type="hidden" name="bot-field" />
                <input className="hidden" type="hidden" name="form-name" value="Contact Page" />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  Message <span className="asterisk">*</span>
                  <textarea name="message" id="message"></textarea>
                </label>
              </div>
              <div className="form-group">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query contactQuery {
    contentfulPage(title: { eq: "Contact" }) {
      id
      bodyContent {
        json
      }
      heading {
        json
      }
      featuredImage {
        fluid(maxWidth: 1150) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  }
`
